
import Vue from 'vue'

export default Vue.extend({
  name: 'Footer',
  data: () => ({
    textLinks: []
  }),
  watch: {
    '$i18n.locale'() {
      this.createTextLinks()
    }
  },
  created() {
    this.createTextLinks()
  },
  methods: {
    handleLinkClick(link) {
      if (link.val) {
        this.toggleCookies()
      } else {
        this.openLink(link.link)
      }
    },
    openLink(link) {
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null
        newWindow.focus()
      } else {
        alert(
          'I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.'
        )
      }
    },
    createTextLinks() {
      this.textLinks = [
        {
          name: 'Privacy',
          link: 'https://media.cving.com/privacy/'
        },
        {
          name: this.$t('footer.termsAndConditions').toString(),
          link: 'https://media.cving.com/terms-conditions/'
        },
        {
          name: this.$t('footer.transparency').toString(),
          link: 'https://media.cving.com/trasparenza/'
        },
        {
          name: this.$t('footer.cookiePolicy').toString(),
          link: 'https://media.cving.com/cookie-declaration/'
        },
        {
          name: 'Impostazione dei cookies',
          val: 'settings'
        }
      ]
    },
    toggleCookies() {
      document.body.classList.toggle('show-cookies')
    }
  }
})
